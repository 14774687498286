<template>
  <div class="content-section body-wpr">
    <div class="body-wp">
      <div class="statistic-area">
        <div class="statistic-area-wrap">
          <img
            src="@/assets/image/statistic.svg"
            alt="statistic"
            class="statistic-img"
            style="margin-right: 14px"
          />
          <div>
            <p class="statistic-title">전체</p>
            <p class="statistic-count">{{ allCnt }}건</p>
          </div>
        </div>
        <div class="statistic-area-wrap">
          <img
            src="@/assets/image/statistic.svg"
            alt="statistic"
            class="statistic-img"
            style="margin-right: 14px"
          />
          <div>
            <p class="statistic-title">승인거절</p>
            <p class="statistic-count">{{ rejCnt }}건</p>
          </div>
        </div>
        <div class="statistic-area-wrap">
          <img
            src="@/assets/image/statistic.svg"
            alt="statistic"
            class="statistic-img"
            style="margin-right: 14px"
          />
          <div>
            <p class="statistic-title">승인완료</p>
            <p class="statistic-count">{{ appCnt }}건</p>
          </div>
        </div>
      </div>
      <div class="search-area mb-16">
        <!-- 검색설정 -->
        <div class="search_wrap py-16">
          <!-- 조회기간 -->
          <div class="d-flex">
            <div class="search_title fb-16">조회기간</div>
            <div class="search_list pt-16">
              <div class="d-flex align-items-center">
                <input-radio v-model="selectRadio" val-nm="PERIOD"
                  >기간별</input-radio
                >
                <div class="mr-8">
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'ALL' }"
                    @click="selectRange('ALL')"
                  >
                    전체
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'TODAY' }"
                    @click="selectRange('TODAY')"
                  >
                    오늘
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'WEEK' }"
                    @click="selectRange('WEEK')"
                  >
                    1주일
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'MONTH' }"
                    @click="selectRange('MONTH')"
                  >
                    1개월
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{
                      'select-button': selectedButton === 'THREEMONTH',
                    }"
                    @click="selectRange('THREEMONTH')"
                  >
                    3개월
                  </button>
                  <button
                    class="tiny btn-w"
                    :class="{ 'select-button': selectedButton === 'FREE' }"
                    @click="selectRange('FREE')"
                  >
                    직접 설정
                  </button>
                </div>
                <div class="align-items-center">
                  <v-date-picker
                    ref="datepicker"
                    v-model="range"
                    :popover="popover"
                    is-range
                  >
                    <template v-slot="{ inputEvents }">
                      <div class="flex justify-center items-center">
                        <input
                          :value="formatDate(range.start)"
                          v-on="inputEvents.start"
                          :disabled="popover.visibility === 'hidden'"
                          class="my-8 pa-2"
                          style="height: 36px; padding-left: 10px; border: none"
                        />
                        <span class="px-8">~</span>
                        <input
                          :value="formatDate(range.end)"
                          v-on="inputEvents.end"
                          :disabled="popover.visibility === 'hidden'"
                          class="my-8"
                          style="height: 36px; padding-left: 10px; border: none"
                        />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="d-flex align-items-center pb-8">
                <input-radio v-model="selectRadio" val-nm="MONTH"
                  >월별</input-radio
                >
                <div class="align-items-center">
                  <select-box
                    :opt-nm="YEAR_LIST"
                    v-model="selectedYear"
                    class="mr-16"
                    style="width: 120px; text-align: left"
                  />
                  <select-box
                    :opt-nm="MONTH_LIST"
                    v-model="selectedMonth"
                    style="width: 120px; text-align: left"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 상세조건 -->
          <div class="d-flex">
            <div class="search_title fb-16">상세조건</div>
            <div class="search_list align-items-center d-flex py-16">
              <div>
                <select-box
                  :opt-nm="SELT_COMPANY_APPROVE"
                  v-model="params.searchType"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                />
                <!-- 유형 -->
                <select-box
                  :opt-nm="SELT_COMPANY_STATUS_TYPE"
                  v-model="params.searchString"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                  v-if="params.searchType === 'TYPE'"
                />
                <!-- 분야 -->
                <select-box
                  :opt-nm="highCategoryList"
                  v-model="params.highSpecialtyEsntlId"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                  @input="getLowCategory()"
                  v-if="params.searchType === 'SPEC'"
                />
                <select-box
                  :opt-nm="lowCategoryList"
                  v-model="params.lowSpecialtyEsntlId"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                  v-if="params.searchType === 'SPEC'"
                />
                <input
                  class="my-8"
                  style="height: 36px; padding-left: 10px"
                  v-model="params.searchString"
                  v-if="
                    params.searchType === 'COMP' ||
                    params.searchType === 'EMAIL'
                  "
                />
                <!-- 승인현황 -->
                <select-box
                  :opt-nm="SELT_COMPANY_APPROVE_STATUS"
                  v-model="params.searchString"
                  class="mr-16"
                  style="width: 150px; text-align: left"
                  v-if="params.searchType === 'STATUS'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="search_button d-flex justify-content-center">
          <button type="submit" class="small btn-m" @click="getData">
            검색
          </button>
        </div>
      </div>
      <div class="sort-div">
        <span class="sort-count"
          >내역
          <span style="color: #2ccdc3; font-weight: 700">{{ totCnt }}</span>
          건</span
        >
        <div class="d-flex">
          <select-box
            :opt-nm="SELT_ORD"
            v-model="params.order"
            class="mr-16"
            style="width: 120px; text-align: left"
          />
          <select-box
            :opt-nm="SELT_CNT"
            v-model="params.numOfData"
            style="width: 120px; text-align: left"
          />
        </div>
      </div>
      <table class="search-table">
        <thead>
          <colgroup>
            <col v-for="(col, colIdx) in tblCol" :key="colIdx" :width="col" />
          </colgroup>
        </thead>
        <tr>
          <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
        </tr>
        <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
          <td>{{ row.regDate }}</td>
          <td>
            <span style="font-weight: 700">{{
              getEditType(row.editType)
            }}</span>
          </td>
          <td>
            {{ row.highSpecialtyTitle ? row.highSpecialtyTitle : "없음" }} >
            {{ row.lowSpecialtyTitle ? row.lowSpecialtyTitle : "없음" }} >
            {{ row.orderSpecialty ? row.orderSpecialty : "없음" }}
          </td>
          <td>{{ row.compName }}</td>
          <td>{{ row.email }}</td>
          <td>{{ getApprovalStatus(row.approvalStatus) }}</td>
          <td>
            <button-common :size="SIZE_T" :clr="CLR_W" @click="goDetail(row)"
              >보기</button-common
            >
          </td>
        </tr>
      </table>
      <div class="div-search-null" v-if="tblRow.length === 0">
        검색 데이터가 없습니다.
      </div>
      <pagination class="mt-16" @click="onChangePage" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CompanyStatus",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** data ***/
  data() {
    return {
      selectRadio: "PERIOD",
      selectedButton: "ALL",
      selectedYear: "2022",
      selectedMonth: "01",
      params: {
        page: 1,
        numOfData: 10,
        order: "DESC",
        startDate: "",
        endDate: "",
        searchType: "",
        searchString: "",
        highSpecialtyEsntlId: "",
        lowSpecialtyEsntlId: "",
      },
      allCnt: 0,
      appCnt: 0,
      rejCnt: 0,
      totCnt: 0,
      range: {
        start: "",
        end: "",
      },
      popover: {
        visibility: "hidden",
      },
      tblCol: ["20%", "8%", "20%", "20%", "20%", "20%", "8%"],
      tblFld: [
        "신청일시",
        "유형",
        "분야",
        "파트너명",
        "아이디",
        "승인현황",
        "상세",
      ],
      tblRow: [],
      highCategoryList: [],
      lowCategoryList: [],
    };
  },

  /*** created hook ***/
  async created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.setParams();
    }
    await this.getHighCategory();
    await this.getData();
  },

   mounted() {
     if(this.selectRadio === 'PERIOD') {
       this.selectRange(this.selectedButton)
     }
   },

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_PAGE",
      "G_ORDER",
      "G_LSTCNT",
      "G_FOLDER",
      "G_FOLDER_ID",
      "G_FOLDER_ARR",
    ]),
    cls() {
      let cls = "";
      cls += "input-rel ";
      cls += this.wpCls || "";
      return cls;
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    setParams() {
      Object.keys(this.$route.query).forEach(key => {
        if (key === 'selectRadio' || key === 'selectedButton' || key === 'selectedYear' || key === 'selectedMonth') {
          this[key] = this.$route.query[key]
        } else {
          this.params[key] = this.$route.query[key]
        }
      })
    },
    // 데이터 가져오기 공통모듈
    async getHighCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.highCategoryList.unshift({ title: "", value: "" });
        if(this.params.highSpecialtyEsntlId) {
          await this.getLowCategory();
        }
      }
    },
    async getLowCategory() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: this.params.highSpecialtyEsntlId },
      });
      if (rs.result === "SUC") {
        this.lowCategoryList = rs.resultList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.lowCategoryList.unshift({ title: "", value: "" });
      }
    },
    async getData() {
      if (this.selectRadio === "PERIOD") {
        this.params.startDate = this.range.start
          ? this.$moment(this.range.start).format("YYYY-MM-DD")
          : "";
        this.params.endDate = this.range.end
          ? this.$moment(this.range.end).format("YYYY-MM-DD")
          : "";
      } else {
        const date = `${this.selectedYear}-${this.selectedMonth}-01`;
        this.params.startDate = this.$moment(date).format("YYYY-MM-DD");
        this.params.endDate = this.$moment(date)
          .add(1, "months")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      }
      if (this.params.searchType === "SPEC") {
        this.params.searchString = "";
      } else {
        this.params.highSpecialtyEsntlId = "";
        this.params.lowSpecialtyEsntlId = "";
      }
      const rs = await this.$axios({
        url: "/admin/partner/approval/data",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
        this.totCnt = rs.totcnt;
        this.allCnt = rs.allCnt;
        this.appCnt = rs.appCnt;
        this.rejCnt = rs.rejCnt;
        await this.$store.dispatch("A_PAGE", rs.page);
        await this.$store.dispatch("A_TOTCNT", rs.totcnt);
        this.replaceRoute();
      }
    },

    replaceRoute() {
      const selectedParams =
        this.selectRadio === "PERIOD"
          ? {
              selectRadio: this.selectRadio,
              selectedButton: this.selectedButton,
            }
          : {
              selectRadio: this.selectRadio,
              selectedYear: this.selectedYear,
              selectedMonth: this.selectedMonth,
            };
      const queryParams = {...this.params, ...selectedParams}
      if (this.makeQuery(queryParams) !== this.makeQuery(this.$route.query)) {
        this.$router.replace({
          path: `/company/approve?${this.makeQuery(queryParams)}`,
          meta: { id: "11-1-2" },
        });
      }
    },

    makeQuery(json) {
      const URI = encodeURIComponent;
      return Object.entries(json)
        .filter((item) => item[1].toString().length > 0)
        .map(([key, value]) => `${URI(key)}=${URI(value)}`)
        .join("&");
    },

    selectRange(type) {
      this.popover.visibility = "hidden";
      this.selectedButton = type;
      switch (type) {
        case "ALL":
          this.range.start = "";
          this.range.end = "";
          break;
        case "TODAY":
          this.range.start = this.$moment().toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "WEEK":
          this.range.start = this.$moment().add(-7, "days").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "MONTH":
          this.range.start = this.$moment().add(-1, "months").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "THREEMONTH":
          this.range.start = this.$moment().add(-3, "months").toDate();
          this.range.end = this.$moment().toDate();
          this.$refs["datepicker"].updateValue(this.range, "");
          break;
        case "FREE":
          this.popover.visibility = "hover";
          break;
      }
    },

    formatDate(date) {
      return date ? this.$moment(date).format("YYYY.MM.DD") : "";
    },

    getEditType(type) {
      switch (type) {
        case "COMP":
          return "변경_사업자";
        case "BASIC":
          return "변경_기본";
        case "BANK":
          return "변경_정산";
        case "NEW":
          return "신규";
      }
    },

    getApprovalStatus(type) {
      switch (type) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
      }
    },

    // 페이지 이동
    async onChangePage(value) {
      this.params = { ...this.params, page: value };
      this.pager = { ...this.pager, page: value };
      await this.getData();
    },

    goDetail(row) {
      this.$router.push({
        path: `/company/approve/${row.esntlId}`,
        meta: { id: "11-1-2-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.statistic-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding-left: 38px;
}
.statistic-area-wrap {
  display: flex;
  align-items: center;
  margin-right: 100px;
  cursor: pointer;
}
.statistic-img {
  width: 36px;
  height: 36px;
}
.statistic-title {
  font-weight: 400;
  font-size: 14px;
  color: #515466;
}
.statistic-count {
  font-weight: 500;
  font-size: 16px;
  color: #2ccdc3;
}
.sort-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.sort-count {
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.select-button {
  color: #ffffff;
  background: #696c80;
}
.div-search-null {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray20);
}
</style>
